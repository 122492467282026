@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;700&family=Ubuntu:wght@400;500;700&display=swap");

.smart-city-section {
  width: 100vw;
  height: 100vh;
  font-family: "Inter", sans-serif;
  font-family: "Ubuntu", sans-serif;
}

.font-ubuntu {
  font-family: "Ubuntu", sans-serif !important;
}

.custom-marker {
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
}

.mapboxgl-popup {
  max-width: 350px !important;
  max-height: 500px !important;
  overflow: hidden;
}

.mapboxgl-popup-content {
  padding: 0px !important;
  border-radius: 5px;
  overflow: hidden;
}

@supports (-webkit-touch-callout: none) {
  .mapboxgl-popup-close-button {
    background-color: #ffffffd3 !important;
    border-radius: 50% !important;
    width: 25px !important;
    height: 25px !important;
    max-height: 25px !important;
    max-width: 25px !important;
    top: 5px !important;
    right: 5px !important;
    font-size: large !important;
    font-weight: 600 !important;
    outline: none !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
  }

  .mapboxgl-popup-close-button:hover {
    background-color: #fff !important;
    border-radius: 50% !important;
    width: 25px !important;
    height: 25px !important;
    top: 5px !important;
    right: 5px !important;
    font-size: large !important;
    font-weight: 600 !important;
    outline: none !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
  }
}

.mapboxgl-popup-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffd3 !important;
  width: 25px !important;
  height: 25px !important;
  top: 5px !important;
  right: 5px !important;
  font-size: large !important;
  font-weight: 600 !important;
  outline: none !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  border-radius: 50% !important;
}

.mapboxgl-popup-close-button:hover {
  background-color: #fff !important;
  border-radius: 50% !important;
  max-width: 25px !important;
  max-height: 25px !important;
  width: 25px !important;
  height: 25px !important;
  top: 5px !important;
  right: 5px !important;
  font-size: large !important;
  font-weight: 600 !important;
  outline: none !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}

.map-style {
  position: fixed;
  left: 0;
  display: flex;
  gap: 20px;
  padding: 10px;
  border-radius: 0 0 5px;
  background-color: #fff;
  z-index: 10;
  text-transform: capitalize;

  @media (max-width: 999px) {
    flex-direction: column;
    gap: 5px;
    top: 0px;
    border: 1px solid gray;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 5px;
    top: 45px;
    border: 1px solid gray;
  }
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-attrib-inner {
  display: none !important;
}

.smart-city-header {
  position: fixed;
  right: 0px;
  top: 0;
  z-index: 10;
  background-color: #fff;
  border-radius: 0 0px 0px 5px;
  padding: 5px 20px;

  @media (max-width: 500px) {
    width: 100vw;
    border: 1px solid gray;
    border-radius: 0;
  }
}
.mapboxgl-popup-close-button{
  display:none;
}

.map-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: 11;

  .video-element {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 500px) {
      width: 100vw;
      height: auto;
    }
  }
}

.search-marker {
  position: fixed;
  left: 10px;
  bottom: 30px;
  width: 25%;
  z-index: 10;
  padding: 10px;
  transition: all 1s;
  border-radius: 10px;

  input {
    background-color: #ffffffea !important;
  }

  @media (max-width: 500px) {
    width: 80%;
    left: 10px;
    bottom: 10px;
  }
}