:root {
	--primary-color: #3fb6ff;
	--primary-color-opacity: #e0ebfa;
	--secondary-color: #3458b9;
	--font-color: #424242;
	--bg-color: #ffffff;
	--heading-color: #292922;
	--white-color: #ffffff;
	--interBlod-text: interBlod;
	--interLight-text: interLight;
	--interMediun-text: interMediun;
	--interRegular-text: interRegular;
	--interSemiBold-text: interSemiBold;
	--gray-light: #868686;
}
