.login {
    width: 450px;
    @media (max-width: 500px) {
        width: 90%;
        margin-bottom: 40px;
    }
}

.refresh-action{
    position: absolute;
    background: hsla(0,0%,100%,.9);
    border-radius: 8px;
}

.responsive-login{
    @media (min-width: 1024px) {
        height: 100vh !important
    }
}