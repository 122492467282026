@import url(./_variable.scss);

.under-maintenance {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
        width: 70%;
    }
    @media (max-width: 500px) {
        img{
            width: 80%;
        }
    }
}
