.login-section {
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	@media (max-width: 500px) {
		flex-direction: column;
	}
}

.login-form {
	width: 500px;
	box-shadow: 2px 2px 5px #949494;
	@media (max-width: 500px) {
		width: 90%;;
	}
}

.main-div-of-logo-login-sec {
	align-items: center;
}

.logo {
	margin-left: 50%;
	transform: translateX(-50%);
}

.logo-icon {
	width: 25%;
	height: 25%;
}

.eye-btn:hover {
	outline: 0 !important;
}

.pass-input {
	border: 1px solid red !important;
	border-radius: 5px;
}
