.fullscreen-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
	background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(63, 182, 255, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    z-index: 9999; /* Ensure it appears on top of other content */
  }
  