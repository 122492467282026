@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url(./_variable.scss);

body {
  font-family: "Lato";
  font-weight: 400;
  padding-right: 0 !important;
  background-size: cover;
  // height: 100vh;
  // width: 100vw;
  background-position: center;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(63, 182, 255, 1) 100%
  );
}

#root {
  width: 100%;
  height: 100%;
}

.main {
  overflow: hidden;
}

.text-red {
  color: red;
}

.main-wrapper {
  margin-left: 285px;
  height: calc(100vh - 60px);
  overflow: hidden;
  overflow-y: auto;
}

.sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 114%;
}

.sub-paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.fullwidth {
  margin-left: 110px !important;
}

.transiton-02s {
  transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
}

p {
  margin: 0;
  margin-bottom: 0;
}

.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

a {
  text-decoration: none !important;
}

button {
  border: none;
}

.width-920 {
  max-width: 920px;
  width: 100%;
}

.width-900 {
  max-width: 900px;
  width: 100%;
}

.max-width-100 {
  max-width: 100% !important;
}

.minheight-100 {
  min-height: 100vh;
}

/* text properties */

.text-wrap {
  overflow-wrap: break-word !important;
}

/* cursor properties */

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

/* border-radius */

.rounded-5 {
  border-radius: 5px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-15 {
  border-radius: 15px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.border-grey {
  border: 1px solid #dadce0 !important;
}

.border-green {
  border: 1px solid var(--primary-color) !important;
}

.b-none {
  border: none;
}

.welcome-page-container {
  height: 75vh;

  .main-card {
    margin-top: 100px;
  }

  @media (max-width: 500px) {
    height: auto;
    margin-top: 80px;

    .main-card {
      margin-top: 20px;
    }
  }
}

/* Fonts  */

.font-50 {
  font-size: 50px;
  line-height: 22px;
}

.font-40 {
  font-size: 40px;
  line-height: 35px;
}

.font-36 {
  font-size: 36px;
  line-height: 44px;
}

.font-26 {
  font-size: 26px;
  line-height: 30px;
}

.font-20 {
  font-size: 20px;
  line-height: 22px;
}

.font-16 {
  font-size: 16px;
  line-height: 20px;
}

.font-20 {
  font-size: 20px;
  line-height: 30px;
}

.font-15 {
  font-size: 15px;
  line-height: 18px;
}

.font-14 {
  font-size: 14px;
  line-height: 18px;
}

.font-13 {
  font-size: 13px;
  line-height: 26px;
}

.font-12 {
  font-size: 12px;
  line-height: 22px;
}

.font-11 {
  font-size: 11px;
  line-height: 16px;
}

.font-10 {
  font-size: 11px;
  line-height: 14px;
}

/* font-colors  */
.color-theme {
  color: #212529;
}

.metadata-card {
  border: 2px solid var(--primary-color);
}

.c-black {
  color: #0d0f12 !important;
}

.c-gray {
  color: #999999;
}

.c-green {
  color: var(--primary-color) !important;
}

a .c-green:hover {
  color: var(--primary-color) !important;
}

.c-orange {
  color: #052651 !important;
}

a:hover {
  color: var(--primary-color) !important;
}

.c-bg-green {
  background-color: var(--primary-color);
}

.c-bg-green:hover {
  background-color: var(--primary-color);
}

.c-bg-green:active {
  background-color: var(--primary-color) !important;
}

.c-border-green {
  border-color: var(--primary-color);
}

.c-border-green:hover {
  border-color: var(--primary-color);
}

.c-border-green:active {
  border-color: var(--primary-color) !important;
}

.color-light-grey {
  color: #868686;
  word-wrap: break-word;
}

.blue {
  color: #007de4;
}

.min-width {
  min-width: 130px;
}

.gray {
  color: gray;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-50px {
  width: 50px !important;
}

.mw-50px {
  max-width: 50px !important;
}

.mw-75 {
  max-width: 75% !important;
}

.responsive-event-name {
  text-wrap: balance;
  inline-size: 90%;
}

.responsive-event-time {
  text-wrap: balance;
  inline-size: 80%;
}

.customer-plans {
  height: 38px !important;
  color: gray !important;
}

.color-dark-blue {
  color: #073d83;
}

.color-black1,
.active-color-black1:hover {
  color: #0d0f12;
}

.heigth-btn-banji {
  height: 38px;
}

.color-black2,
.active-color-black2:hover,
.active-color-black2:focus,
.active-color-black1:active,
.active-color-black1.active {
  color: #4c4f53;
}

.color-blue,
.active-color-blue:hover,
*:checked + .active-color-blue {
  color: #007de4;
}

*:checked + .active-border-blue,
.active-border-blue:hover {
  border-color: #007de4;
}

.color-white {
  color: #ffffff;
}

.color-light-white {
  color: #ffffffa3;
}

.color-green {
  color: #7ac142;
}

.color-grey {
  color: #dadce0;
}

.active-color-white:hover {
  color: #ffffff;
}

/* background */

.bg_white {
  background: #ffffff;
}

.modalHeader button {
  background-color: #fff !important;
}

.bg-gray1,
.nav-tabs .nav-link.bg-gray1,
.form-control:read-only.bg-gray1,
.form-select:disabled.bg-gray1 {
  background-color: #f8f8f8;
}

.nav-tabs .nav-link:not(.active) {
  border: 1px solid #ddd;
}

.bg-lightblue {
  background: #e8f0fe !important;
}

.bg-lightOrange {
  background-color: var(--primary-color-opacity) !important;
}

.bg-blue {
  background: #007de4 !important;
}

.bg-disable-blue {
  background: #51aaf3 !important;
}

.bg-red {
  background: #f64e60 !important;
}

.bg-grey,
.edit-input:focus {
  background-color: #dadce0;
}

.bg-test,
.edit-input:focus {
  background-color: #007de4;
}

.vh-60 {
  height: 60vh;
}

.mt-30 {
  margin-top: 30px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-0 {
  margin-top: 0;
}

.my_10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.px-10 {
  padding: 0 10px;
}

.p-0 {
  padding: 0;
}

.py-20 {
  padding: 20px;
}

.py-15 {
  padding: 15px 0;
}

.py-10 {
  padding: 10px 0;
}

.p_5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.p-50 {
  padding: 50px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

/* font-weight  */
.fw-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

/* disable  */

.disable {
  opacity: 0.3;
  pointer-events: none !important;
}

.icon-sm {
  font-size: 0.9em;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-80px {
  width: 80px;
}

.h-80px {
  height: 80px;
}

.mw-80px {
  max-width: 80px;
}

.mh-80px {
  max-height: 80px;
}

.w-250px {
  width: 250px;
}

.w-115px {
  width: 115px;
  min-width: 115px;
}

.h-50px {
  height: 50px;
}

.h-40 {
  height: 40px;
}

.h-20 {
  height: 20% !important;
}

.mh-20 {
  max-height: 20% !important;
}

.mh-50px {
  max-height: 50px !important;
}

.dropdown-menu {
  font-size: inherit;
  z-index: 3;
}

.form-control:focus {
  box-shadow: none;
}

.custom-button {
  padding: 5px;
  width: 80px;
  height: 60px;
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
}

.btn-comman {
  min-width: 117px;
  height: 37px;
  border-radius: 4px;
  font-size: 15px;
  text-transform: capitalize;
}

.text-upper {
  text-transform: uppercase;
  color: #4c4f53;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.z-index {
  z-index: 2;
}

.info-name {
  min-width: 210px;
  text-transform: uppercase;
}

.info-image .photo {
  width: 60px;
  height: 60px;
  background-color: #c5c5c5;
  border-radius: 50%;
  margin-right: 20px;
  overflow: hidden;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #143d83;
}

input:focus + .slider {
  box-shadow: 0 0 1px #143d83;
}

.link-resend {
  color: #143d83;
  font-weight: 700;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.edit-profile .info-field .info-image {
  overflow: hidden;
}

.roleModule {
  width: 33%;
}

hr {
  clear: both;
}

.input-hieght {
  min-height: 38px !important;
}

/* remove bottom border of last table row */
.table-section table {
  margin-bottom: 0;
}

/* remove border of first child */
.table > :not(:first-child) {
  border-top: 0px solid currentColor;
}

/* object fit cover */
.object-fit-cover {
  object-fit: cover !important;
}

.button-w-h-add {
  max-width: 160px;
  max-height: 37px;
}

.button-w-h-close {
  max-width: 47px;
  max-height: 37px;
}

/* hide arrow icons input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* custom invalid type */
.custom-invalid ~ .invalid-feedback {
  display: block;
}

.button-width-com {
  width: 100px !important;
  height: 38px !important;
}

.hover-btn:hover {
  background-color: #dadce0 !important;
}

.button-width-com-big {
  width: 150px !important;
  height: 38px !important;
}

.hover-page {
  color: #868686;
}

.hover-page:hover {
  background-color: #d3d3d3 !important;
  color: #0d0f12;
}

.notification-box {
  position: absolute;
  right: 19px;
  top: 19px;
  background: #007de4;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
}

.notification-dot-icon {
  color: red;
}

/* poll-history page related css */
.poll-title {
  color: #005397;
  width: 100%;
  overflow: hidden;
  font-size: 34px;
  word-break: break-all;
  font-weight: 600;
  line-height: 52px;
}

.poll-sub-title {
  color: #005397;
  font-size: 24px;
  font-weight: 600;
}

.poll-dowload-button {
  color: #fff;
  padding: 15px 35px;
  font-size: 20px;
  border-radius: 18px;
  text-transform: capitalize;
  background-color: #00519a;
  font-weight: 500;
  line-height: 1.75;
  outline: 0 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.poll-footer {
  background: rgb(0, 83, 151);
  border-radius: 10px;
  margin: 20px -20px -20px -20px;
  color: white;
  padding: 20px;
}

.poll-text {
  color: #9eaeba;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.poll-normal {
  border: 1px solid #eef0f8;
  margin-top: 30px;
  border-radius: 12px;
  background-color: #ffffff;
  overflow: hidden;
}

.poll-span {
  max-width: 22px;
  background-color: rgb(0, 83, 151);
  border-radius: 12px;
}

.poll-span-button {
  min-width: 100px;
  height: 40px;
  border-radius: 40px;
  background: #00519a;
  color: #fff;
  font-weight: 500;
}

.poll-qr-dowload {
  width: 60px;
  background-color: #005397;
  height: 50px;
  border-radius: 12px;
  color: #fff;
  display: flex;
}

.poll-option {
  border: 1px solid #eef0f8;
  margin-top: 30px;
  border-radius: 12px;
  background: linear-gradient(0deg, #05539b 16%, #096ac4 100%);
  overflow: hidden;
  padding: 20px;
}

.voter-modal button {
  background: #fff;
  color: gray;
  font-size: 28px;
}

.voter-modal h6 {
  font-size: 1.25rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.6;
}

.created-date {
  min-width: 200px !important;
}

/* position relative */
.position-relative {
  position: relative;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.animatedListItem-enter {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
}

.animatedListItem-enter-active {
  opacity: 1;
  transform-origin: top;
  transform: scaleY(1);
  transition: all 200ms ease-out;
}

.animatedListItem-exit {
  opacity: 1;
  transform: scaleY(1);
  transform-origin: top;
}

.animatedListItem-exit-active {
  transform-origin: top;
  opacity: 0;
  transform: scaleY(0);
  transition: all 200ms ease-in;
}

/* box shadow design for list item input type */
.list-item-box-shadow {
  box-shadow: 3px 3px 8px 0px rgba(128, 128, 128, 0.24);
}

/* not allowed cursor */

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.w-500 .react-datepicker-wrapper {
  width: 500px !important;
}

@media (max-width: 1399px) {
  .font-36 {
    font-size: 32px;
    line-height: 38px;
  }

  .font-30 {
    font-size: 26px;
    line-height: 32px;
  }
}

@media (max-width: 1199px) {
  .main-wrapper {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .main-wrapper {
    height: calc(100vh - 116.03px);
  }
}

@media only screen and (max-width: 767px) {
  .mr-20 {
    margin-right: 10px;
  }

  .mb-20 {
    margin-bottom: 10px;
  }

  .mb-30 {
    margin-bottom: 15px;
  }

  .font-20 {
    font-size: 16px;
  }

  .w-60 {
    width: 100%;
  }

  .w-40 {
    width: 100%;
  }

  .roleModule {
    width: 100%;
    margin-top: 20px;
  }
}

.analytics-button {
  color: #007de4;
  background-color: #fff;
  border-color: #007de4;
  border: 1px solid;
  font-weight: 600;
  padding: 2px 12px;
  font-size: 13px;
  border-radius: 4px;
  transition: 0.3s;
}

.analytics-button:hover {
  color: #fff;
  background-color: #007de4;
  border-color: #fff;
  border: 1px solid;
}

@media (max-width: 575px) {
  .filters select {
    width: calc(50% - 8px);
    margin-right: 16px !important;
  }

  .filters select.second {
    margin-right: 0 !important;
  }

  .filters .search-input {
    margin-right: 0 !important;
  }
}

@media (max-width: 374px) {
  .filters select {
    width: 100%;
    margin-right: 0 !important;
  }
}

@media (min-width: 700px) {
  .w-sm-250px {
    width: 250px !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
}

.admin-table table th {
  min-width: 100px;
}

.admin-table table {
  table-layout: fixed;
  word-break: break-all;
  min-width: 1580px;
}

.banji-table table {
  table-layout: fixed;
  word-break: break-all;
  min-width: 1580px;
}

.bvote-table table {
  table-layout: fixed;
  word-break: break-all;
  min-width: 1580px;
}

.nft-label-section {
  height: 600px;
  overflow: auto;
  overflow-x: hidden;
}

.nft-price {
  margin-top: 20px;
  border: 1px solid #fff;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
}

.nft-price-input {
  width: 30%;
}

.nft-price-input label {
  font-size: 14px;
  font-weight: 700;
}

.nft-price-input input {
  font-size: 14px;
  margin-top: 5px;
}

@media (max-width: 767.98px) {
  .nft-price-input {
    width: 100%;
  }
}

.block-chain-header {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 15px 15px;
  margin: 10px 0px;
  background: #e8f0fe;
  font-weight: 600;
}

.block-chain-object {
  padding: 15px;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 3px;
  cursor: move !important;
}

.ml-30 {
  margin-left: 30px;
}

.blockchain-main-section {
  width: 650px;
}

.blockchainNote {
  color: gray;
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .blockchain-main-section {
    width: 100%;
  }

  .blockchain-main-section img {
    width: 100%;
    height: 100%;
  }

  .ml-30 {
    margin-left: 20px;
  }

  .drag-blockchain-img {
    display: none;
  }
}

.category-icon-position {
  position: absolute;
  top: 20px;
  right: 20px;
}

.w-140 {
  width: 140px !important;
}

.w-750 {
  width: 75%;
}

@media (max-width: 767.98px) {
  .w-750 {
    width: 100%;
  }
}

.cutome-radio {
  width: 130px;
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgb(197, 196, 196);
  font-size: 15px;
  font-weight: 600;
}

@media (max-width: 650px) {
  .cutome-radio {
    margin-left: 0;
    margin-top: 10px;
  }
}

.cutome-radio div {
  width: 80px;
}

.w-c-250 {
  width: 250px;
}

.red-c-color .css-1s2u09g-control {
  border: 1px solid red !important;
}

.error-border input {
  border-color: #dc3545 !important;
}

.app-custom {
  border: 1px solid #dadce0;
  background-color: #dadce0;
  border-radius: 5px;
  margin-right: 3px;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: 700;
}

.ReactTagInput-error .react-tag-input {
  border: 1px solid red;
}

.ReactDatePickerError {
  border: 1px solid red;
}

.content_d {
  border: 1px solid #dadce0 !important;
  min-height: 38px;
  border-radius: 5px;
}

.content_red {
  border: 1px solid red !important;
  border-radius: 5px;
  min-height: 38px;
}

.selectTags .css-loboqh-control {
  border: 1px solid red !important;
}

.markeplace-form {
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-top: 15px;
  padding: 10px 10px 10px 10px;
}

.markeplace-form label {
  font-weight: 700;
}

.markeplace-form .error-text {
  color: #ee5060;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ee5060;
}

.markeplace-form-loadding {
  position: absolute;
  top: 50%;
  left: 45%;
  display: flex;
  justify-content: center;
}

.markeplace-form-main label {
  font-weight: 700;
}

.parent-app .css-1s2u09g-control {
  border: 1px solid #dc3545 !important;
}

.btn-primary {
  padding: 8px;
}

.table-section .status-data {
  padding: 17px 17px 17px 35px !important;
}

.table-section .status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.table .active {
  background-color: var(--primary-color);
}

.table .deactive {
  background-color: #ea2027;
}

.table .pending {
  background-color: #eaac20;
}

.table .inprocess {
  background-color: #f75c1e;
}

.table .InProgress {
  background-color: #eaac20;
}

.table .Declined {
  background-color: #ea2027;
}

.table .Scheduled {
  background-color: #20eadb;
}

.table .Completed {
  background-color: #28c76f;
}

.table .Approved {
  background-color: #2d7de4;
}

.custom-primary-outline {
  font-size: 18px;
  line-height: 20px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  outline: none;
  box-shadow: none;
  background-color: #fff;
  border-radius: 5px;
  padding: 0px 20px;
  height: 40px;
  max-height: 40px;
  font-size: 16px;
  transition: all 0.2s;
}

.custom-primary-outline:hover {
  background-color: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}

.custom-primary-outline:disabled {
  opacity: 0.7;
}

.custom-primary {
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  border: 1px solid var(--primary-color);
  outline: none;
  box-shadow: none;
  border-radius: 5px;
  font-size: 16px;
  padding: 0px 20px;
  height: 40px;
  max-height: 40px;
  background: var(--primary-color) !important;
}

.custom-primary:disabled {
  opacity: 0.7;
}

.height-46 {
  height: 46px;
  max-height: 46px;
}

.custom-primary:hover {
  border: 1px solid var(--primary-color);
}

.holders {
  padding: 10px 30px 0px 30px;
}

.holders .thumb-horizontal,
.thumb-vertical {
  display: block;
}

.row-count {
  padding: 2px 7px !important;
  font-size: 13px !important;
  color: #868686 !important;
}

.row-count:focus {
  box-shadow: none;
}

.pagination-section ul {
  margin: 0;
}

.pagination-number {
  width: 30px;
  height: 30px;
}

.pagination-number a {
  color: var(--primary-color);
}

.disabled {
  cursor: no-drop;
  opacity: 0.6;
}

.pagination-number a {
  width: 100%;
  height: 100%;
}

.app-permission.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.pagination-number a:focus {
  box-shadow: none;
}

.pagination-number a:hover,
.pagination-number a:hover i {
  background-color: #e8f0fe;
  color: #0d0f12;
}

.pagination-number i {
  line-height: 15px;
  color: #868686;
  width: fit-content;
}

@media only screen and (max-width: 768px) {
  .holders {
    padding: 30px 15px;
  }

  .holder-form {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 460px) {
  .pagination-section {
    margin-top: 10px;
  }
}

.table-section {
  border-radius: 5px;
}

.table-section thead {
  background-color: #e8f0fe;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-section tbody tr {
  border-bottom: 1px solid #dadce0;
}

.table-section thead th,
tbody td {
  border: none;
}

.table-section thead th,
tbody th,
tbody td {
  padding: 17px !important;
}

.table-section .status-data {
  padding: 17px 17px 17px 35px !important;
}

.thumb-horizontal,
.thumb-vertical {
  background-color: #007de4;
  border-radius: 10px;
}

.table-section .holder-profile {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.table-section .holder-profile img {
  width: 100%;
  height: 100%;
}

.table-section .status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.switch-margin-0 {
  margin-bottom: 0 !important;
}

#react-select-2-listbox {
  z-index: 7 !important;
}

#react-select-2-listbox .css-1h06qz8-control {
  height: 100%;
}

#react-select-2-listbox .css-lj6lawv-control {
  height: 100%;
}

#react-select-2-listbox .css-lj6lawv-control:hover {
  height: 100%;
}

.css-13cymwt-control {
  height: 42px !important;
}

.css-t3ipsp-control {
  height: 42px !important;
}

.react-select-error {
  border: none;
  border: 1px solid #dc3545;
  border-radius: 5px;
}

.react-select-error .css-13cymwt-control {
  border: none;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}

.form-control:focus {
  border: 1px solid #ced4da;
}

.sidebar-toggle {
  border-left: 1px solid var(--primary-color);
}

.table-box-height {
  height: 38px;
}

.react-switch-handle {
  box-shadow: none !important;
}

.user-details {
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  padding: 10px 30px;
}

.react-datepicker-popper {
  z-index: 10 !important;
  padding: 20px !important;
}

.error-date {
  border: 1px solid #dc3545 !important;
}

.react-datepicker__input-container input {
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
  border: 1px solid #cccccc;
  width: 100% !important;
}

.passcode::placeholder {
  color: #828488;
  font-size: 14px;
}

hr {
  color: var(--primary-color);
  opacity: 1;
}

.r-25 {
  right: 25px;
}

.r-45 {
  right: 45px;
}

.t-25 {
  top: 25px;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  max-height: 80vh !important;
  object-fit: cover;
  max-width: 100%;
  height: auto;
}

.report-card {
  align-items: flex-start;
  border-radius: 10px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  overflow: hidden;
  border: 1px solid #e9ecef;
}

.report-card-title {
  display: flex;
  justify-content: space-between;
}

.report-card-img {
  border-radius: 10px;
  width: 150px;
  max-height: 150px;
}

.mb-80 {
  margin-bottom: 80px;
}

.modal-close {
  top: 15px;
  right: 15px;
  z-index: 100;
}

@media (max-width: 768px) {
  .qr-code-filter {
    flex-direction: column;
    gap: 15px;
    height: 85px;
  }
}

.report-card-body p {
  width: 100%;
  text-align: justify;
}

.exit-section {
  background-color: #0d0f12;
  color: #fff;
  font-size: medium;
  padding: 10px;
}

.exit-section span {
  border: 1px solid #fff;
  padding: 2px 5px;
  margin: 0 5px;
}

.img-optional-section {
  width: 150px;
  height: 140px;
  border-radius: 10px;
  background: #e0ebfa;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  font-weight: 600;
  padding: 10px;
}

.w-16 {
  width: 16px !important;
}

.error-select .css-1h06qz8-control {
  border: 1px solid #dc3545;
}

.error-select .css-1h06qz8-control:hover {
  border: 1px solid #dc3545;
}

@media (max-width: 575px) {
  .exit-section {
    display: none;
  }

  .report-card-title {
    flex-direction: column;
  }

  .report-card-title p {
    width: 100% !important;
  }

  .report-card-body {
    flex-direction: column;
  }

  .report-card-body p {
    width: 100%;
    text-align: justify;
  }

  .report-card-body img {
    width: 100%;
  }

  .qr-code-filter-search {
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .qr-code-filter-search button {
    height: 140px;
    margin-left: 0;
  }

  #react-select-2-listbox {
    margin-left: 0;
  }

  .qr-code-filter {
    flex-direction: column;
    gap: 15px;
    height: 185px;
  }

  .card-section-v {
    overflow: unset !important;
    margin-bottom: 20px !important;
    max-height: 100% !important;
  }
}

.top-redirect {
  background-color: #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  font-size: 25px;
  color: var(--primary-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.table-box-height {
  gap: 10px;
  height: auto;
}

@media screen and (max-width: 1024px) {
  .table-box-height {
    height: auto;
    flex-direction: column;
    gap: 10px;
    flex-shrink: 10px;
  }

  .table-box-height button {
    margin-left: 0;
  }
}

input::placeholder {
  color: #808080 !important;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

::-webkit-scrollbar {
  position: fixed;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(238, 5, 5, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #bdebfd;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.download-png {
  border: 1px solid var(--primary-color);
  color: #0d0f12;
  transition: all 0.3s;
  font-weight: 600;

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
}

@media only screen and (max-width: 600px) {
  .logo-responsive {
    width: 85%;
    margin-top: 20px;
  }

  .text-change {
    text-align: center;
  }

  .login-layout {
    padding: 25px !important;
  }

  .login {
    margin-bottom: 10px !important;
  }

  // welcome page css for ios
  .main-card {
    flex-wrap: wrap;
  }
}

// welcome page css for ios
.main-card {
  display: flex;
}

.rm-hover {
  &:hover {
    color: white !important;
  }

  width: 24%;

  @media (max-width: 500px) {
    width: 100% !important;
  }

  @media (max-width: 1024px) {
    width: 48%;
  }
}

.ellipsis {
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden; /* keeps the element from overflowing its parent */
  inline-size: 75%;
  @media (min-width: 1024px) {
    inline-size: 100%;
  }
  @media (max-width: 500px) {
    inline-size: 65%;
  }
}

.nft-responsive-text {
  color: black;
  margin-top: 30px;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 22px;
  }
}
