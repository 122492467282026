@import url(./_variable.scss);

.banner-section {
    // width: 99.6vw !important;
    margin-top: 57px;
    height: 200px;
    img {
        height: 200px;
    }
}
.profile-img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    top: -75px;
    border: 5px solid white;
}

.edit-profile {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    // font-weight: 600;
    padding: 2px 10px;
    font-size: small;
    border-radius: 50px;
    border: 1px solid #fff;
    &:hover {
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
    }
}
.text-change{
    color: var(--primary-color);
    @media (max-width: 500px) {
      color: #0d0f12 !important;
    }
}
.profile-edit{
    @media (max-width: 500px) {
        width: 100%;
    }
}