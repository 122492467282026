.p-validate {
	display: flex;
}
.p-validate-qrcode {
	background: #e8f0fe;
	border-radius: 10px;
}
.p-validate-card {
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin-top: 100px;
}
.p-validate-title {
	background: #e8f0fe;
	padding: 10px;
	border-radius: 10px;
}
.p-validate-title input {
	background: #e8f0fe;
	border-color: #e8f0fe;
}

.p-validate-title .form-control:focus {
	background: #e8f0fe;
	border-color: #e8f0fe;
}
.input-disabled:disabled {
	background: #e8f0fe;
}
.validate-hash-media {
	max-height: 60vh;
}
.p-validate-card-report{
	width: 75%;
}

@media screen and (max-width: 800px) {
	.p-validate {
		flex-direction: column;
		gap: 20px;
		justify-content: flex-start;
		overflow: auto;
	}
	.p-validate-card {
		margin: 30px 0;
	}
	.p-validate-card-report {
		width: 90% !important;
	}
	.blockchain-card {
		gap: 20px;
		flex-direction: column-reverse;
	}
	.blockchain-card div {
		width: 100%;
	}
}
.footer-validate {
	position: fixed;
	bottom: 0;
	margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
	.p-validate {
		flex-direction: column;
	}
	.validate-hash-media {
		max-height: auto !important;
	}
	.p-validate-card {
		width: 100%;
	}
	.loading-spinner {
		display: none;
	}
	.footer-validate {
		position: inherit;
		margin-bottom: 10px;
	}
}
@media (max-width: 575px) {
	.p-validate-card {
		margin: 30px 0;
	}
	.p-validate-card-report {
		width: 90% !important;
	}
	.blockchain-card {
		gap: 20px;
		flex-direction: column-reverse;
	}
	.blockchain-card div {
		width: 100% !important;
	}

	.validate-hash-media {
		max-height: none !important;
	}
}
