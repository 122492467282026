@import url(./_variable.scss);

.nft-header {
  background: var(--primary-color);
  width: 100vw !important;
  top: 0;
  right: 0;
  padding: 0 60px;
  z-index: 10;
  @media (max-width:500px) {
    padding : 0 10px;

  }
}

.react-switch-handle{
  background-color: var(--primary-color) !important;
}

.nft-section {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(63, 182, 255, 1) 100%);
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.metadata-card {
  border: 2px solid var(--primary-color);
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ntf-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.2s;
  opacity: 1;
  img {
    scale: 1;
    transition: all 0.2s;
  }
  .body {
    background-color: #fff;
  }
}
.ntf-card:hover {
  img {
    scale: 1.1;
  }
}

.c-gray {
  color: var(--gray-light) !important;
}
.nft-details-img {
  width: 100%;
  height: 600px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
}

.nft-value {
  border: 1px dashed #d6d6d6;
  border-radius: 5px;
  padding: 6px 15px;
}

.gofullpageicon {
  position: absolute;
  height: 45px;
  width: 45px;
  top: 20px;
  right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  background-color: hsla(0, 0%, 100%, 0.753);
  border-radius: 50px;
}

.modal-content2 {
  max-width: 90vw;
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2px;
}
.modal-nft-img {
  .modal-content {
    border: none;
    img {
      min-width: 400px;
    }
  }
}
.nft-more-page-loader {
  background-color: var(--primary-color);
  padding: 20px;
  width: 200px;
  text-align: center;
  border-radius: 5px;
}

.nft-filter-card {
  padding: 10px 15px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  font-size: 16px;
  color: #0d0f12;
  transition: all 0.2s;
  &:hover {
    color: #fff !important;
    background-color: var(--primary-color);
  }
}

.nft-filter-card-active{
  color: #fff !important;
  background-color: var(--primary-color);
}
/*
 * grid container
 */
#grid {
  height: 100%;
  display: grid;
  grid-gap: 10px 10px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.cards {
  display: flex;
}

.card {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  .image {
    img {
      max-width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .content {
    padding: 20px;
  }
}

.card.is-loading {
  // .image,
  h2,
  p {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
  .image {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
  h2 {
    height: 20px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.public-qrcode {
  width: 265px;
  padding: 16px 12px 10px 12px;
  background:
    linear-gradient(to right, var(--primary-color) 4px, transparent 4px) 0 0,
    linear-gradient(to right, var(--primary-color) 4px, transparent 4px) 0 100%,
    linear-gradient(to left, var(--primary-color) 4px, transparent 4px) 100% 0,
    linear-gradient(to left, var(--primary-color) 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, var(--primary-color) 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, var(--primary-color) 4px, transparent 4px) 100% 0,
    linear-gradient(to top, var(--primary-color) 4px, transparent 4px) 0 100%,
    linear-gradient(to top, var(--primary-color) 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 25px 25px;
}
.qrcode-nft-modal {
  .modal-content {
    border-radius: 40px;
  }
}

.public-nft-footer {
  @media (max-width: 480px) {
    div {
      text-align: center;
    }
    .public-nft-footer-title {
      margin-top: 30px;
    }
    .public-nft-footer-explore {
      align-items: center !important;
    }
    p {
      text-align: center;
    }
    img {
      width: 80%;
    }
    span {
      font-size: 20px;
      display: block;
      margin-left: 0;
      margin-bottom: 20px !important;
    }
  }
}

.public-nft-filter {
  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
}
.nft-toggle-public {
  @media (max-width: 480px) {
    flex-direction: column;
    margin-top: 20px !important;
    align-items: flex-start !important;
    gap: 10px !important;
    p{
      width: 100% !important;
    }
    div{
      width: 100% !important;
    }
  }
}

.public-nft-modal-img {
  .modal-content {
    border: none;
    background-color: transparent !important;
  }
  @media (max-width: 480px) {
    .modal-content {
      background-color: transparent !important;
      height: 10px !important;
      border: none !important;
    }
  }
}

.public-nft-details-section {
  @media (max-width: 480px) {
    margin-top: 20px;
  }
}
