@import url(./_variable.scss);

.header-link {
    cursor: pointer;
    font-weight: 600;
    color: #0d0f12;
    transition: 0.1s;
    &:hover {
        color: #fff;
    }
}
.header-drop-down {
    padding: 10px 20px;
    color: #0d0f12;
    cursor: pointer;
    border-bottom: 1px solid #e9ecef;
    &:hover {
        background-color: #e9ecef !important;
    }
}

.notification-bell {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    right: 0px;
}

.profile-img-header {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #fff;
}

.dropdown-menu-right {
    width: 500px;
    @media (max-width: 500px) {
        width: 95vw;
    }
}

