.custom-border {
    color: #000;   
}

.custom-border-red{
    border: 1px solid red !important;
}
.meta-data-card {
    background-color: #fff;
}

.max-category-menu {
    .css-4ljt47-MenuList {
        max-height: 118px !important;
        overflow-y: auto !important;
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            background-color: #808080;
        }
    }
    .category-type__menu-list {
        max-height: 118px !important;
        overflow-y: auto !important;
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #808080;
            border-radius: 5px;
        }
    }
    .css-13cymwt-control {
        height: 66px !important;
        border-radius: 8px !important;   
    }
    .css-t3ipsp-control {
        height: 66px !important;
    }
}

.cursor-select {
    cursor: pointer;
    div {
        cursor: pointer;
    }
    .category-type__input {
        font-weight: 400 !important;
    }
    .category-type__placeholder {
        @extend .category-type__input;
        font-size: 14px !important;
    }
    .category-type__value-container {
        padding-left: 20px !important;
    }
    .category-type__dropdown-indicator {
        padding-right: 20px !important;
    }
}


.error-select .css-13cymwt-control {
	border: 1px solid #dc3545 !important;
}
.error-select .css-13cymwt-control:hover {
	border: 1px solid #dc3545 !important;
}